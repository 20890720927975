* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
  
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1 {
  color: #85ac11;
}

h1 {
  color: #85ac11;
}

h1 {
  color: #85ac11;
}

h1 {
  color: #85ac11;
}

h5 {
  color: #617e0a;
}

.viewPassword, .deleteField, .searchField {
  padding: 0.39rem 0.70rem !important;
  border-radius: 0 25% 25% 0 !important;
  height: 100%;
}

.cleanField {
  padding: 0.39rem 0.70rem !important;
  border-radius: 0 0 0 0 !important;
  height: 100%;
}

.textareCampo {
  height: 100px !important;
}

/* Loader */
.contenedor_loader {
  position: fixed;
  height: 100vh;
  width: 100%;
  background-color: rgba(1,1,1,0.6);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contenedor_animacion {
  margin-top: -20px;
}

/* Breadcrumb */
.breadcrumb-item + .breadcrumb-item::before {
	color: #9e9e9e;
	font-size: medium;
	content: ">" !important;
}

.breadcrumb-item {
	font-size: medium;
	font-weight: 700;
}

.bread-active{
	color: #85ac11;
}

.bread-link{
	color: #9e9e9e;
	text-decoration: none;
}

/* Select */
.select-group {
  width: 100%;
}

.react-select__control {
  height: 100%;
}

.contenedor-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

/* Datepicker */
.input-datepícker {
  padding: 1rem 0.75rem;
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}

.label-datepicker {
  opacity: .65;
  transform: scale(.85) translateY(-0.5rem) translateX(0.15rem);
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}

/* Timepicker */
.react-time-picker__wrapper {
  border: transparent!important;
}

/* React Calendar */
.texto-completo {
  white-space: normal;
  font-size: 12px;
}

/* Botones */
.btn-primary {
  --bs-btn-color: #000;
  --bs-btn-bg: #caec67;
  --bs-btn-border-color: #caec67;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #85ac11;
  --bs-btn-hover-border-color: #617e0a;
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #617e0a;
  --bs-btn-active-border-color: #617e0a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #caec67;
  --bs-btn-disabled-border-color: #caec67;
}

.btn-success {
  --bs-btn-color: #FFF;
  --bs-btn-bg: #879A04;
  --bs-btn-border-color: #879A04;
  --bs-btn-hover-color: #FFF;
  --bs-btn-hover-bg: #5C6803;
  --bs-btn-hover-border-color: #5C6803;
  --bs-btn-focus-shadow-rgb: 60,153,110;
  --bs-btn-active-color: #FFF;
  --bs-btn-active-bg: #5C6803;
  --bs-btn-active-border-color: #5C6803;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #FFF;
  --bs-btn-disabled-bg: #879A04;
  --bs-btn-disabled-border-color: #879A04;
}

.btn-outline-primary {
  --bs-btn-color: #617e0a;
  --bs-btn-border-color: #617e0a;
  --bs-btn-hover-color: #FFFFFF;
  --bs-btn-hover-bg: #617e0a;
  --bs-btn-hover-border-color: #617e0a;
  --bs-btn-focus-shadow-rgb: 13,110,253;
  --bs-btn-active-color: #FFFFFF;
  --bs-btn-active-bg: #617e0a;
  --bs-btn-active-border-color: #617e0a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #617e0a;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #617e0a;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #5C6803;
  --bs-btn-border-color: #5C6803;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #5C6803;
  --bs-btn-hover-border-color: #5C6803;
  --bs-btn-focus-shadow-rgb: 25,135,84;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #5C6803;
  --bs-btn-active-border-color: #5C6803;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #5C6803;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #5C6803;
  --bs-gradient: none;
}

.btn-check:checked+.btn {
  --bs-btn-active-color: #FFF;
  --bs-btn-active-bg: #617e0a;
  --bs-btn-active-border-color: #617e0a;
}

/* Navegaciones */
.nav {
  --bs-link-color: #85ac11;
  --bs-link-hover-color: #caec67;
  --bs-nav-pills-link-active-bg: #85ac11;
}

.navbar {
  --bs-navbar-active-color: rgb(255, 0, 0);
}

/* Textos */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(70, 74, 192, 1) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(17, 131, 172, 1) !important;
}
 
.text-warning {
  --bs-text-opacity: 1;
  color: rgba(204, 158, 0, 1) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(154, 0, 84, 1) !important;
}

/* Fondos */
.bg-primary {
  --bs-bg-opacity: 1;
  background-color: #caec67 !important;
}

/* Permisos */
.subnivel {
  margin-left: 20px;
}